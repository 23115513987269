<template>
  <header aria-label="Webpage">
    <div class="w-[92%] max-w-[1540px] flex items-center mx-auto">

      <NuxtLink v-if="!hideLogo" :href="homeUrlPath" aria-label="Logo" :class="primaryLogoSize">
        <img v-if="layout === 'cruise' || invertLogo" src="~/assets/images/logo_cruises_2.svg" :class="primaryLogoSize" class="logo" width="80" height="40" alt="Brand">
        <img v-else src="~/assets/images/logo.svg" :class="primaryLogoSize" class="logo" width="80" height="40" alt="Brand">
      </NuxtLink>

      <div class="flex flex-col flex-grow items-end justify-center">
        <div class="flex flex-wrap flex-col items-end text-end">
          <div class="flex">
            <NuxtIcon name="ion:logo-whatsapp" size="20px" class="mt-1 sm:mt-1.5 mr-2" :class="[invertColor || 'text-[#B49657]']" />
            <div class="font-bold text-xl sm:text-[21px] tracking-tight" :class="[invertColor || 'text-stone-700']">{{ phoneNumber }}</div>
          </div>
          <div v-if="layout === 'cruise'" class="text-sm sm:text-lg">
            <span>Mobil & Ausland:&nbsp;</span><span class="sm:hidden"><br></span>
            <span>{{ company.phoneNumberCruisesInternational }}</span>
          </div>
          <div class="min-w-[240px] text-xs" :class="[invertColor || 'text-stone-500 tracking-tighter']">{{ company.openingHoursLong }}</div>
        </div>
        <div v-if="!invertColor" class="max-sm:hidden mt-5 pt-1 flex items-center flex-row gap-8 text-sm tracking-tight text-stone-500">
          <div class="flex items-center gap-2">
            <NuxtIcon name="majesticons:ship" size="20px" class="text-[#B49657]" />
            <NuxtLink href="/kreuzfahrten" :class="[primaryNavStyles]">Kreuzfahrten</NuxtLink>
          </div>
          <div class="flex items-center gap-2">
            <NuxtIcon name="majesticons:beach-line" size="20px" class="text-[#B49657]" />
            <NuxtLink href="/pauschalreisen/suche" :class="[primaryNavStyles]">Pauschalreisen</NuxtLink>
          </div>
          <div class="flex items-center 1gap-2">
            <NuxtLink href="/service/reiseberatung" class="px-5 py-1.5 inline-block bg-black text-[#fafcfd]">Meine Reise</NuxtLink>
          </div>
        </div>
      </div>

    </div>
  </header>
</template>

<script setup lang="ts">
export interface Props {
  hideLogo?: boolean
  invertLogo?: boolean
  layout?: 'default' | 'cruise'
}
const props = withDefaults(defineProps<Props>(), {
  layout: 'default',
})
const { company } = useAppConfig()
const phoneNumber = props.layout === 'cruise' ? company.phoneNumberCruises : company.phoneNumber
const homeUrlPath = '/'
const invertColor = props.layout === 'cruise' || props.invertLogo ? 'text-inherit' : undefined
const primaryNavStyles = 'border-b-[1px] border-transparent hover:border-stone-800 hover:border-b-[1px]'
const primaryLogoSize = 'w-20 h-auto sm:w-[88px] sm:h-auto'
</script>
